import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import Link from '../components/Link';
import LocaleSwitch from '../components/LocaleSwitch';
import MenuButton from '../components/MenuButton';
import useOutsideClick from '../hooks/useOutsideClick';
import useTranslation from '../hooks/useTranslation';
import useUrlLocalization from '../hooks/useUrlLocalization';
import { useLayoutActions, useLayoutState } from './Context';
import LogoSrc from '../images/logo.svg';

const MENU_TRANSITION_OPEN = { type: 'spring', stiffness: 120, damping: 20 };
const MENU_TRANSITION_CLOSE = { type: 'spring', stiffness: 120, damping: 15 };

const sidebarVariants = {
  menuOpen: () => ({
    x: '0%',
    transition: MENU_TRANSITION_OPEN,
  }),
  menuClosed: () => ({
    x: '100%',
    transition: MENU_TRANSITION_CLOSE,
  }),
};

const NavigationSidebar = () => {
    const ref = useRef();
    const { menuOpen, locale, isMediumScreen } = useLayoutState();
    const { closeMenu } = useLayoutActions();
    const { t } = useTranslation('navigation', locale);
    const { url } = useUrlLocalization(locale);

    useOutsideClick(ref, closeMenu, isMediumScreen);

    return (
      <motion.div
        className="bg-black fixed flex flex-col w-full sm:w-96 top-0 right-0 h-screen z-10"
        variants={sidebarVariants}
        animate={menuOpen ? 'menuOpen' : 'menuClosed'}
        initial={false}
        ref={ref}
      >
        <div className="flex items-center px-4 h-24 relative z-10">
          <Link
            to="/"
            onClick={() => closeMenu()}
            className="flex-1 block no-underline mr-auto"
            aria-label="Cyberforumkyiv"
          >
            <img src={LogoSrc} alt="Logo" className="h-12 lg:h-14" />
          </Link>
          <MenuButton
            isActive={menuOpen}
            onClick={() => closeMenu()}
          />
        </div>
        <div className="py-4 px-8 relative z-10">
          <nav className="flex flex-col gap-4 py-2 rounded uppercase text-right">
            <Link to={`${url('/#agenda')}`} className="typo-intro text-green-400 no-underline" onClick={closeMenu}>
              {t('Agenda')}
            </Link>
            <Link to={`${url('/#speakers')}`} className="typo-intro text-green-400 no-underline" onClick={closeMenu}>
              {t('Speakers')}
            </Link>
            <Link to={`${url('/#news')}`} className="typo-intro text-green-400 no-underline" onClick={closeMenu}>
              {t('News')}
            </Link>
            <Link to={`${url('/#contacts')}`} className="typo-intro text-green-400 no-underline" onClick={closeMenu}>
              {t('Contacts')}
            </Link>
            <Link to="https://cyberforumkyiv.org/2024/" className="typo-intro text-green-400 no-underline">
              {t('KICRF – 2024')}
            </Link>
          </nav>
          <div className="flex justify-end mt-4">
            <LocaleSwitch locale={locale} />
          </div>
        </div>
      </motion.div>
    );
};

export default NavigationSidebar;
